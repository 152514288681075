import React, { useState, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { Index } from 'elasticlunr';
import { graphql, Link, useStaticQuery } from 'gatsby';
import SEO from '../components/SEO';
import WithHeroLayout from '../layouts/WithHeroLayout';
import Input from '../components/form-elements/Input';
import LinkButton from '../components/form-elements/LinkButton';
import SectionDescription from '../components/formatting/SectionDescription';
import { device } from '../constants';

const Container = styled.div`
  padding: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    padding: calc(${props => props.theme.paddings.pd}px * 2.5);
  }
`;

const ContentContainer = styled.div`
  display: flex;
`;

const PostList = styled.div`
  margin-right: calc(${props => props.theme.paddings.pd}px * 1.5);
`;
const Sidebar = styled.div`
  flex: 1 0 350px;
  margin-top: 48px;
  padding-top: ${props => props.theme.paddings.pd}px;
  border-top: 1px solid ${props => props.theme.colors.purple};
`;
const SidebarLink = styled.a`
  color: #000;
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
`;
const Widget = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.paddings.pd}px;
`;
const WidgetTitle = styled.h3`
  color: #000;
  font-family: 'Open Sans';
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
`;

const PostItem = styled.article`
  display: flex;
  flex-flow: row wrap-reverse;

  margin-bottom: 48px;
  padding-bottom: ${props => props.theme.paddings.pd}px;
  border-bottom: 1px solid ${props => props.theme.colors.purple};
`;
const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
`;

const PostContentContainer = styled.div`
  flex: 1 1 50%;
  margin-right: ${props => props.theme.paddings.pd * 2}px;
`;
const LinkButtonWrapper = styled.div`
  display: inline-block;
`;

const SearchPage: React.FC = function (props: any) {
  const queryIndex = useStaticQuery(graphql`
    query SearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `);

  const index = Index.load(queryIndex.siteSearchIndex.index);
  const [query, setSearchQuery] = useState(props.location?.state?.query ?? '');

  const search = () => {
    return index.search(query, { expand: true }).map(({ ref }) => {
      return index.documentStore.getDoc(ref);
    });
  };

  const [results, setResults] = useState(search());
  const theme = useTheme();

  useMemo(() => {
    setResults(search());
  }, [query]);

  return (
    <WithHeroLayout
      pageTitle="Search Results"
      bgImgSrc="journal" // @TODO: Make configurable from CMS
      color={theme.colors.purple} // @TODO: Make configurable from CMS
    >
      <SEO title="Search Results" />
      <Container>
        {/* @TODO: Make below paragraph configurable in CMS */}
        <SectionDescription>
          Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
          fugit, sed quia consequuntur amet elit.
        </SectionDescription>
        <ContentContainer>
          <PostList>
            {results.map((page: any, index: number) => (
              <PostItem key={index}>
                <PostContentContainer>
                  <Title>
                    <Link to={`/post${page.frontmatter.link}`}>
                      {page.title}
                    </Link>
                  </Title>
                  <LinkButtonWrapper>
                    <LinkButton
                      buttonType="outline"
                      link={`/post${page.frontmatter.link}`}
                      buttonText="Read More"
                      background={'#FFF'}
                    />
                  </LinkButtonWrapper>
                </PostContentContainer>
              </PostItem>
            ))}
          </PostList>
          <Sidebar>
            <Widget>
              <WidgetTitle>Search</WidgetTitle>
              <Input
                type="search"
                onChange={(e: any) => setSearchQuery(e.target.value)}
                placeholder="Search title, keywords ..."
                value={query}
              />
            </Widget>
          </Sidebar>
        </ContentContainer>
      </Container>
    </WithHeroLayout>
  );
};

export default SearchPage;
